<template>
  <div>
    <v-container>
      <v-breadcrumbs
        :items="breadcrumbs"
        class="pa-0 pb-2"
        divider=">"
      ></v-breadcrumbs>
      <v-row align="center">
        <v-col cols="auto" class="pr-12">
          <h1>Orders</h1>
        </v-col>
        <v-col class="pl-12">
          <v-text-field
            label="Search Orders"
            v-model="searchTerm"
            outlined
            dense
            hide-details
            prepend-inner-icon="mdi-magnify"
          ></v-text-field>
        </v-col>
        <v-col cols="auto">
          <v-row align="center" justify="end" no-gutters> </v-row>
        </v-col>
      </v-row>
      <v-data-table
        :headers="tableHeaders"
        :items="orders"
        :disable-pagination="true"
        :hide-default-footer="true"
        no-data-text="There are currently no orders"
      >
        <template v-slot:item.id="{ item }">
          <router-link
            :to="{
              name: 'module-grogreen-orders-individual',
              params: { orderId: item.id },
            }"
            >#{{ item.identifier }} {{ item.customer.full_name }}</router-link
          >
        </template>
        <template v-slot:item.status="{ item }">
          <v-chip
            label
            v-if="item.status === 'processing'"
            color="blue"
            text-color="white"
            >Processing</v-chip
          >
          <v-chip
            label
            v-else-if="item.status === 'pending-payment'"
            color="warning"
            >Pending Payment</v-chip
          >
          <v-chip label v-else-if="item.status === 'cancelled'"
            >Cancelled</v-chip
          >
          <v-chip label v-else-if="item.status === 'complete'" color="success"
            >Complete</v-chip
          >
        </template>
        <template v-slot:item.total="{ item }"> £{{ item.total }} </template>
        <template v-slot:item.actions="{ item }">
          <v-btn
            x-small
            depressed
            color="green lighten-4 green--text"
            class="mr-2"
            :to="{
              name: 'module-grogreen-orders-individual',
              params: { orderId: item.id },
            }"
          >
            <v-icon x-small left>mdi-eye</v-icon> View
          </v-btn>
          <v-tooltip bottom>
            <template v-slot:activator="{ on }">
              <v-btn
                x-small
                depressed
                color="red lighten-4 red--text"
                v-on="on"
              >
                <v-icon x-small>mdi-archive</v-icon>
              </v-btn>
            </template>
            <span>Archive</span>
          </v-tooltip>
        </template>
      </v-data-table>
    </v-container>
    <v-dialog v-model="deleteOrder.dialog" persistent max-width="400">
      <v-card>
        <v-card-title class="headline">Delete Order</v-card-title>
        <v-card-text
          >Are you sure you wish to delete
          {{ deleteOrder.order.id }}?</v-card-text
        >
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn color="error" depressed>No, cancel</v-btn>
          <v-btn color="success" depressed :loading="deleteOrder.loading"
            >Yes</v-btn
          >
        </v-card-actions>
      </v-card>
    </v-dialog>
  </div>
</template>

<script>
export default {
  data() {
    return {
      searchTerm: "",
      breadcrumbs: [
        {
          text: "groGreen",
          disabled: false,
          to: { name: "app-dashboard", params: { id: this.$route.params.id } },
        },
        {
          text: "Orders",
          disabled: true,
        },
      ],
      tableHeaders: [
        { text: "Order", value: "id" },
        { text: "Date Placed", value: "formatted_dates.created_at" },
        { text: "Status", value: "status" },
        { text: "Total", value: "total" },
        { text: "Actions", value: "actions", sortable: false, align: "right" },
      ],
      deleteOrder: {
        dialog: false,
        order: {},
        loading: false,
      },
    };
  },

  computed: {
    orders() {
      let orders = this.$store.state.grogreen.orders["orders"];

      if (this.searchTerm !== "") {
        orders = orders.filter((o) => {
          const index = String(o.id).toLowerCase();
          const name = o.customer.full_name.toLowerCase();
          return (
            name.includes(this.searchTerm.toLowerCase()) ||
            index.includes(this.searchTerm.toLowerCase())
          );
        });
      }

      return orders;
    },
  },

  methods: {},
};
</script>
